import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubTitle from "../components/subTitle"
import { FaArrowCircleDown } from "react-icons/fa"

const fakeNews = [
  {
    id: 0,
    url: "https://skeinet.com/visioncare/news1.jpg",
    title: "J&J completa l’acquisizione di AMO",
    date: "18 maggio 2017",
    object:
      "Oggetto: Johnson & Johnson completa l’acquisizione di Abbott Medical Optics",
    text: "Bla bla bla...",
  },
  {
    id: 1,
    url: "https://skeinet.com/visioncare/news2.jpg",
    title: "locchiolino è sempre con te",
    date: "18 maggio 2017",
    object:
      "Oggetto: Johnson & Johnson completa l’acquisizione di Abbott Medical Optics",
    text:
      "Una leggera montatura, giovane e ricercata, completamente sviluppata e prodotta in Italia, in un piccolo paese ai piedi delle Prealpi Lombarde. Dove la ricerca di belle forme e del dettaglio sono stati valori da aggiungere al nostro occhialino. Anche le lenti, di alta qualità, hanno seguito lo stesso iter. Perché un occhiale può essere costruito bene ed essere anche bello.",
  },
  {
    id: 2,
    url: "https://skeinet.com/visioncare/news3.jpg",
    title: "Multi Clean+ Pulizia biotecnologica",
    date: "18 maggio 2017",
    object:
      "Oggetto: Johnson & Johnson completa l’acquisizione di Abbott Medical Optics",
    text:
      "Pulizia biotecnologica grazie all’idrolisi. L’idrolisi è un processo grazie al quale delle sostanze, grassi e sporco, diventano sottoprodotti solubili. Questa soluzione unica biotecnologica rompe le particelle di grasso e sporco, prevenendone l’accumulo. Per occhiali e altri dispositivi.",
  },
]

class Articolo extends Component {
  state = {
    article: {},
  }

  componentDidMount = () => {
    this.setState({ article: { ...fakeNews[0] } })
  }

  render() {
    const { article } = this.state
    return (
      <Layout>
        <SEO title="News" keywords={[`vision care`]} description="News" />
        <div className="row">
          <div className="maxWidth1080 divCentered">
            <div className="px-3 pt-3 l">
              <SubTitle label={article.title} size="large" />
              <img src={article.url} className="img-news" alt="articolo" />
              <div className="row p-3">
                Scarica il comunicato stampa{" "}
                <FaArrowCircleDown className="ml-2 mt-1" />
              </div>
              <div className="row p-3">
                <strong>{article.object}</strong>
              </div>
              <div className="row p-3">{article.text}</div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Articolo
