import React, { Component } from "react"

class Title extends Component {
  state = {
    CustonTag: "h3",
  }

  componentDidMount = () => {
    let h = 3
    switch (this.props.size) {
      case "small":
        h = 5
        break
      case "medium":
        h = 3
        break
      case "large":
        h = 2
        break
      default:
        h = 2
        break
    }
    const CustonTag = "h" + h
    this.setState({ CustonTag })
  }

  render() {
    const { CustonTag } = this.state
    return (
      <CustonTag>
        <strong>{this.props.label}</strong>
        <hr />
      </CustonTag>
    )
  }
}

export default Title
